<script lang="ts" setup>
import { useApi } from '@web-admin/composables'
const tabPageConfig = [
  {
    label: '书籍推荐',
    value: 1,
  },
  {
    label: '促销活动',
    value: 2,
  },
  {
    label: '系统消息',
    value: 0,
  },
]

const messageListMap = $ref<{ [key: string]: any }>({})
let unreadCount = $ref(0)
onMounted(() => {
  // 一分钟刷新一次全类型消息
  setInterval(() => {
    fetchMessageList()
  }, 1000 * 60)
})

fetchMessageList()
async function fetchMessageList() {
  const { error, data } = await useApi('announcement/list/user').get().json()
  if (error.value)
    return
  const totalList = data?.value?.data || []
  for (let i = 0; i < tabPageConfig.length; i++) {
    const type = tabPageConfig[i].value
    messageListMap[type] = totalList.filter((item: any) => item.type === type)
  }
  const unreadList = totalList.filter((item: any) => item.seenFlag === 0)
  unreadCount = unreadList.length
}

const activeName = $ref(tabPageConfig[0].value)
let drawerVisible = $ref(false)
let messageInfo: any = $ref({})
async function viewMessage(item: any) {
  messageInfo = item
  drawerVisible = true
  if (item.seenFlag === 0) {
    await useApi(`announcement/view/${item.id}`).post()
    fetchMessageList()
  }
}

// 点击富文本编辑器中的图片
function handleClickEditor(event) {
  if (event.target?.tagName === 'IMG' && event.target.hasAttribute('data-href')) {
    const href = event.target.getAttribute('data-href')
    if (href)
      window.open(event.target.getAttribute('data-href'))
  }
}
</script>

<template>
  <div class="message-wrap">
    <div v-if="unreadCount" class="unread-tip">
      {{ unreadCount }}
    </div>
    <el-popover placement="bottom" :width="400" trigger="click">
      <div class="message-pop">
        <div class="tab-list">
          <div v-for="(tab, index) in tabPageConfig" :key="index" class="tab-item" :class="{ active: activeName === tab.value }" @click="activeName = tab.value">
            {{ tab.label }}
          </div>
        </div>
        <div class="message-list">
          <div v-if="!(messageListMap[activeName] || []).length" class="empty">
            暂无内容
          </div>
          <div v-for="(msg, index) in messageListMap[activeName]" v-else :key="index" class="message-item" :class="{ unread: msg.seenFlag === 0 }">
            <div class="notice-name" :class="{ highlight: msg.highlightFlag === 1 }" @click="viewMessage(msg)">
              <i v-if="msg.seenFlag === 0" class="icon-tip" />
              <i v-if="msg.seenFlag === 0 && msg.showFlag === 0" class="hot-flag" />
              <span>{{ msg.name }}</span>
            </div>
            <div class="time">
              {{ msg.updateDateTime }}
            </div>
          </div>
        </div>
      </div>
      <template #reference>
        <el-icon class="h32px">
          <IconAssetsNotice />
        </el-icon>
      </template>
    </el-popover>
    <el-drawer
      v-model="drawerVisible"
      size="600px"
      direction="rtl"
      append-to-body
      destroy-on-close
    >
      <template #header>
        <div class="notice-name" :class="{ highlight: messageInfo.highlightFlag === 1 }">
          <i v-if="messageInfo.showFlag === 0" class="hot-flag" />
          {{ messageInfo.name }}
        </div>
      </template>
      <template #default>
        <div class="message-content" @click="handleClickEditor" v-html="messageInfo.info" />
      </template>
    </el-drawer>
  </div>
</template>

<style scoped lang="scss">
.notice-name {
  color: #1D2129;
}
.tab-list {
  display: flex;
  padding: 8px 20px;
  .tab-item {
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    cursor: pointer;
    &.active {
      background: #F2F3F5;
      color: #165DFF;
    }
  }
}
.message-pop {
  margin: -12px;
}
.message-wrap {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #F2F3F5;
  display: flex;
  align-items: center;
  justify-content: center;
  .unread-tip {
    position: absolute;
    top: -9px;
    left: 18px;
    min-width: 12px;
    padding: 0 2px;
    height: 16px;
    border-radius: 10px;
    background: #F53F3F;
    color: #fff;
    font-size: 10px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center
  }
}
.message-list {
  max-height: 290px;
  overflow-y: auto;
  border-top: 1px solid #E5E6EB;

  .empty {
    padding: 30px 20px 40px 20px;
    text-align: center;
    color: #86909C;
  }

  .message-item {
    padding: 0 20px;
    height: 58px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E6EB;
    .icon-tip {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: #F53F3F;
      display: inline-block;
      margin-right: 6px;
      vertical-align: 2px;
    }
    .time {
      width: 150px;
      text-align: right;
      color: #86909C;
      margin-left: 10px;
    }
    .notice-name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #1D2129;
      cursor: pointer;
    }
    &:first-child {
      border-top: none;
    }

    &.unread {
      .notice-name {
        color: #000;

        &.highlight {
          color: #165DFF;
        }
      }
    }
  }
}
h4.notice-name.highlight {
  color: #165DFF;
}
.message-content {
  // margin: 20px;
  background: #fff;
  // padding: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.hot-flag {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  background: url('../assets/hot.svg') no-repeat;
  background-size: contain;
  margin-right: 5px;
  vertical-align: -3px;
}
</style>

<style lang="scss">
.el-drawer {
  background: #F7F8FA !important;
}
.el-drawer__header {
  margin-bottom: 0;
  background: #fff;
  padding: 0 20px;
}
.message-content {
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    th {
      background: #f5f2f0;
    }
    td, th {
      padding: 3px 5px;
      border: 1px solid #ccc;
      &:empty {
        height: 1em;
      }
    }
  }
  hr {
    margin: 40px 0;
  }
}
</style>
