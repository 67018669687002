import { type UserModule } from '~/types'
import { toArray } from '~/utils'

export const install: UserModule = ({ app }) => {
  // Define `v-permission` directive
  app.directive('permission', {
    mounted(el, binding) {
      const authStore = useAuthStore()
      const permissions = authStore.account?.perms.map(perm => perm.val) ?? []
      if ((!binding.modifiers.force && permissions.includes('*')) || !binding.value)
        return
      if (toArray(binding.value).some(perm => permissions.includes(perm)))
        return

      // Remove element
      el.parentNode.removeChild(el)
    },
  })
}
