/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app, router }) => {
  // TODO: Config Sentry
  // Sentry.init({
  //   debug: import.meta.env.MODE === 'development',
  //   logErrors: true,
  //   app,
  //   dsn: 'https://xxx@sentry.17k.com/3',
  //   environment: import.meta.env.MODE,
  //   integrations: [
  //     new BrowserTracing({
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //       tracingOrigins: ['localhost', 'xxx.17k.com/', /^\//],
  //     }),
  //   ],
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // })
}
