import type { UserModule } from '~/types'

/**
 * 点击后取消聚焦，用于按钮样式恢复
 */
const blurDirective = {
  mounted(el: any) {
    el.addEventListener('click', () => {
      el.blur()
    })
  },
}

export const install: UserModule = ({ app }) => {
  app.directive('blur', blurDirective)
}
