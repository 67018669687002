<script lang="ts" setup>
import type { RouteRecordRaw } from 'vue-router'
import { capitalize } from '~/utils'
const props = defineProps<{
  item: RouteRecordRaw
}>()

const { drawerCollapse } = useAppStore()

const formatTitle = computed(() => {
  const { title, name } = props.item.meta ?? ({} as any)
  return title || capitalize(name)
})
</script>

<template>
  <template v-if="!item.children">
    <router-link :to="{ name: item.name }" class="no-underline">
      <el-menu-item :index="item.path" @click="drawerCollapse">
        <el-icon v-if="item.meta?.icon">
          <component :is="item.meta?.icon" />
        </el-icon>
        <template #title>
          {{ formatTitle }}
        </template>
      </el-menu-item>
    </router-link>
  </template>
  <el-sub-menu v-else ref="subMenu" :index="item.path">
    <template #title>
      <el-icon v-if="item.meta?.icon">
        <component :is="item.meta?.icon" />
      </el-icon>
      <span>{{ formatTitle }}</span>
    </template>
    <AsideMenuItem
      v-for="child in item.children"
      :key="child.path"
      :item="child"
    />
  </el-sub-menu>
</template>
