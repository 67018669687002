<script lang="ts" setup>
const day = String(new Date().getDate())
const isDayShow = useLocalStorage('WARN_POPUP_SHOW', null, { type: String })
const isShow = ref(false)

useRouter().afterEach((to) => {
  if (to.name === 'login') {
    isDayShow.value = null
    isShow.value = false
  }
  else if ($isLogin() && !isShow.value && isDayShow.value !== day) {
    isDayShow.value = day
    isShow.value = true
  }
})
</script>)

<template>
  <el-dialog
    v-model="isShow"
    width="600"
    modal-class="bg"
    class="box"
    :show-close="false"
    center
    :close-on-click-modal="false"
  >
    <div class="context">
      <h3>您正在浏览涉密数据。请务必注意:</h3>

      <p class="li">
        <b>严格保密</b>：此信息属于公司秘密，严禁向未经授权的人员透露。
      </p>

      <p class="li">
        <b>安全操作</b>：确保您的设备安全，避免在公共或不安全的环境下处理此类信息。
      </p>

      <p class="li">
        <b>合规使用</b>：仅在授权范围内使用此信息，并遵守所有相关的数据安全政策和法规。
      </p>

      <p><i>违反上述规定将可能导致严重后果，包括但不限于法律追责</i></p>

      <p><i>安全为先，保密为重!</i></p>

      <div class="buttons">
        <el-button
          class="w50"
          type="primary"
          color="#d62432"
          size="large"
          round
          @click="isShow = false"
        >
          我已了解
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.bg {
  background-color: rgba(0, 0, 0, 0.7);
}

.box {
  background: #fff1b8;
  border-radius: 10px;

  .context {
    padding: 0 4em 3em;

    h3 {
      color: #d62432;
      font-size: 1.2em;
      margin-bottom: 2em;
    }

    p.li {
      padding-left: 5em;
      text-indent: -5em;
      line-height: 1.8;
    }

    b {
      color: black;
    }

    .buttons {
      margin-top: 3em;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
