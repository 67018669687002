<script lang="ts" setup>
import type { RouteRecordRaw } from 'vue-router'
import { config } from '~/config'
import { MENUS } from '~/constants'
import { clone, isAccessRight, remove } from '~/utils'

const props = defineProps({
  collapsed: Boolean,
})
defineEmits(['update:collapsed'])

const { account } = useAuthStore()

const permissions = $computed<string[]>(() => account?.perms.filter(perm => perm.val.startsWith('m:') || perm.val === '*').map(perm => perm.val) ?? [])
// TODO: Refactor to permission module
const menus = $computed<RouteRecordRaw[]>(() => {
  if (permissions.length === 0)
    return []

  const _menus: RouteRecordRaw[] = []
  ;(function traverse(menus: RouteRecordRaw[], parent?: RouteRecordRaw) {
    for (let idx = 0; idx < menus.length;) {
      const menu = menus[idx]
      // filter `onlyUsernames`
      if (menu.meta!.onlyUsernames) {
        if ((menu.meta!.onlyUsernames as any[])?.includes(account?.userName)) {
          !parent && _menus.push(menu)
          idx++
        }
        else {
          parent ? remove(parent?.children ?? [], menu) : idx++
        }
      }
      else {
        if ((permissions.includes(menu.meta?.perm as string) || permissions.includes('*')) && isAccessRight(account, menu.meta)) {
          !parent && _menus.push(menu)
          idx++
        }
        else {
          parent ? remove(parent?.children ?? [], menu) : idx++
        }
      }
      if (menu.children)
        traverse(menu.children, menu)
    }
  })(clone(MENUS))

  return _menus
})

function getActivePath(path: string) {
  // eslint-disable-next-line array-callback-return
  menus.map((item) => {
    if (item?.children) {
      const hasMatchingChild = item?.children.filter(
        (child) => {
          return path.includes(child.path)
        },
      )
      if (hasMatchingChild.length > 0) {
        path = hasMatchingChild[0].path
        return true
      }
    }
  })
  return path
}

watch(
  () => props.collapsed,
  () => {
    // TODO:
    // eslint-disable-next-line no-console
    console.log(props.collapsed)
  },
)
</script>

<template>
  <el-menu
    :unique-opened="true"
    :collapse="collapsed" :default-active="getActivePath($route.path)"
    :style="{
      '--el-menu-level-padding': '30px',
      '--el-menu-text-color': config.menu.textColor,
      '--el-menu-active-color': config.menu.activeTextColor,
      '--el-menu-bg-color': config.menu.bgColor,
      '--el-menu-hover-bg-color': config.menu.hoverBgColor,
      '--el-menu-acitve-bg-color': config.menu.activeBgColor,
      '--el-menu-border-color': config.menu.borderColor,
    }"
  >
    <AsideMenuItem v-for="menu in menus" :key="menu.path" :item="menu" />
  </el-menu>
</template>

<style scoped>
/* TODO: some styles */
/* Some colors are not exposed via css variable */
:deep(.el-sub-menu__title:hover),
:deep(.el-menu-item:hover) {
  color: v-bind('config.menu.hoverTextColor');
}
:deep(.el-menu-item.is-active) {
  background-color: v-bind('config.menu.activeBgColor');
  font-weight: 500;
}
:deep(.el-sub-menu.is-active .el-sub-menu__title) {
  color: v-bind('config.menu.activeTextColor');
}
</style>
