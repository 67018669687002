import { createPinia } from 'pinia'
import { markRaw } from 'vue'
import { type Router } from 'vue-router'
import { type UserModule } from '~/types'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}

// Setup Pinia
// https://pinia.esm.dev/
export const install: UserModule = ({ app, router }) => {
  const pinia = createPinia()
  pinia.use(({ store }) => {
    store.router = markRaw(router)
  })
  app.use(pinia)
}
