<script lang="ts" setup>
import { config } from '~/config'
import { ADMIN_PAGES } from '~/constants'
// setupWindowResize()

const appStore = useAppStore()
const { asideToggle, drawerCollapse, drawerToggle } = appStore
const { device, asideCollapsed, drawerDisplay } = storeToRefs(appStore)

function onToggleMenuCollapsed() {
  if (device.value === 'mobile')
    drawerToggle()
  else
    asideToggle()
}

const route = useRoute()
const routes = ref<any[]>([])

const transparent = computed(() => {
  return Boolean(route.meta.transparent)
})

watch(() => route.path, () => {
  routes.value = []
  const segments = route.path.slice(1).split('/').slice(0, -1)
  let path = ''
  for (const segment of segments) {
    path += `/${segment}`
    const page = ADMIN_PAGES.find(page => page.path === path)
    if (page)
      routes.value.push(page)
  }
  routes.value.push(route)
}, { immediate: true })
</script>

<template>
  <el-container>
    <el-aside
      :style="{ background: config.aside.bgColor, color: config.aside.color, zIndex: 2 }"
      :width="asideCollapsed ? 'auto' : config.aside.width"
    >
      <AsideLogo :collapsed="asideCollapsed" />
      <div class="relative" style="height: calc(100vh - 100px);">
        <el-scrollbar class="overflow-hidden">
          <AsideMenu v-model:collapsed="asideCollapsed" />
        </el-scrollbar>
        <el-icon
          class="nav-shrink w-6 h-6 bg-#F7F8FA cursor-pointer absolute right-5 bottom-2"
          @click="asideToggle"
        >
          <icon-icon-park-menu-unfold v-show="asideCollapsed" />
          <icon-icon-park-menu-fold v-show="!asideCollapsed" />
        </el-icon>
      </div>
    </el-aside>

    <el-container bg="#f5f7f9" h="100vh">
      <el-header class="bg-white h-60px box-border" style="border-bottom: 1px solid #e5e6eb;">
        <Navbar @update:collapsed="onToggleMenuCollapsed" />
      </el-header>
      <el-main>
        <el-breadcrumb flex items="center">
          <el-breadcrumb-item v-for="(item, index) in routes" :key="index" :to="{ path: item.path }">
            {{ item?.meta?.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="mt-4 p-4" :class="{ 'bg-white rounded': !transparent }">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<style scoped>
/* TODO: */
:deep(.el-drawer) {
  --el-drawer-bg-color: v-bind('config.drawer.bgColor') !important;
  --el-drawer-padding-primary: v-bind('config.drawer.padding') !important;
}
:deep(.el-breadcrumb__item .el-breadcrumb__inner) {
  font-weight: normal;
  color: #4E5969;
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
  font-weight: bold;
  color: #1D2129;
}
.nav-shrink {
  position: absolute;
  bottom: 0;
}
</style>
