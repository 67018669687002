import { type UserModule } from '~/types'

import IconAssetsNotice from '~icons/assets/notice'
import IconAssetsContent from '~icons/assets/content'
import IconAssetsDistributor from '~icons/assets/distributor'
import IconAssetsStatistics from '~icons/assets/statistics'
import IconAssetsPromotion from '~icons/assets/promotion'
import IconAssetsPush from '~icons/assets/push'
import IconAssetsOperation from '~icons/assets/operation'
import IconAssetsSettlement from '~icons/assets/settlement'
import IconAssetsFinance from '~icons/assets/finance'
import IconAssetsPlatform from '~icons/assets/platform'
import IconAssetsPermission from '~icons/assets/permission'
import IconAssetsSystem from '~icons/assets/system'
import IconAssetsSearch from '~icons/assets/search'
import IconAssetsRefresh from '~icons/assets/refresh'
import IconAssetsShare from '~icons/assets/share'
import IconAssetsDownload from '~icons/assets/download'
import IconAssetsAdd from '~icons/assets/add'
import IconAssetsDoubleDown from '~icons/assets/double-down'
import IconAssetsAnalysis from '~icons/assets/analysis'
import IconAssetsArrow from '~icons/assets/arrow'
import IconAdvertisement from '~icons/assets/advertisement'
import IconEpDocumentRemove from '~icons/ep/document-remove'
// TODO: 自动生成扫描文件生成
// Register some custom icons to global component
// `unplugin-icons` 必须静态扫描组件, 无法动态加载, 我们再菜单导航中用的 icon 需要在此处全局注册
export const install: UserModule = ({ app }) => {
  app.component('IconAssetsNotice', IconAssetsNotice)
  app.component('IconAssetsContent', IconAssetsContent)
  app.component('IconAssetsDistributor', IconAssetsDistributor)
  app.component('IconAssetsStatistics', IconAssetsStatistics)
  app.component('IconAssetsPromotion', IconAssetsPromotion)
  app.component('IconAssetsPush', IconAssetsPush)
  app.component('IconAssetsOperation', IconAssetsOperation)
  app.component('IconAssetsSettlement', IconAssetsSettlement)
  app.component('IconAssetsFinance', IconAssetsFinance)
  app.component('IconAssetsPlatform', IconAssetsPlatform)
  app.component('IconAssetsPermission', IconAssetsPermission)
  app.component('IconAssetsSystem', IconAssetsSystem)
  app.component('IconAssetsSearch', IconAssetsSearch)
  app.component('IconAssetsRefresh', IconAssetsRefresh)
  app.component('IconAssetsShare', IconAssetsShare)
  app.component('IconAssetsDownload', IconAssetsDownload)
  app.component('IconAssetsAdd', IconAssetsAdd)
  app.component('IconAssetsDoubleDown', IconAssetsDoubleDown)
  app.component('IconAssetsAnalysis', IconAssetsAnalysis)
  app.component('IconAssetsArrow', IconAssetsArrow)
  app.component('IconAssetsAdvertisement', IconAdvertisement)
  app.component('IconEpDocumentRemove', IconEpDocumentRemove)
}
