

import __layout_0 from '/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/blank.vue'),
'default': __layout_0,
'components/AsideLogo': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/components/AsideLogo.vue'),
'components/AsideMenu': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/components/AsideMenu.vue'),
'components/AsideMenuItem': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/components/AsideMenuItem.vue'),
'components/Navbar': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/components/Navbar.vue'),
'components/TabsView': () => import('/root/workspace/web-admin_hz7Z/apps/admin/src/layouts/components/TabsView.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
